<template>
  <div class="row">
    <div class="col-12">
      <div class="card concard card-shadow h-100">
        <Header></Header>
        <AppealTitles class="p-3"></AppealTitles>
        <Navbar></Navbar>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, inject } from "vue";
import Header from "@/components/Header";
import AppealTitles from "@/components/Appeal/AppealTitle";
import Navbar from "@/components/Navbar";


export default defineComponent({
  name: "Profile",
  components: {
    Header,
    AppealTitles,
    Navbar,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });
  },
});
</script>

<style scoped></style>
