<template>
    <div class="bg-color pt-3">
        <ul class="nav justify-content-center text-center gap-2 fs-3 fw-bolder">
            <li class="nav-item" @click="Goto('History')">
                <img src="@/assets/document.png" width="75" alt="">
                <a class="nav-link text-white" href="#">การดำเนินการ</a>
            </li>
            <li class="nav-item" @click="Goto('Dashboard')">
                <img src="@/assets/appeal.png" width="75" alt="">
                <a class="nav-link text-white" href="#">ร้องทุกข์</a>
            </li>
        </ul>
    </div>
    <!--  -->
</template>

<style scoped>
.bg-color { 
    background: var(--color);
}
.nav-item{
    background: linear-gradient(180deg, #674188 0%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%);
    min-width: 150px;
    padding: 10px 0;
    border-radius: 10% 10% 40% 40% / 10% 10% 0% 0% ;
}
</style>

<script setup>
import { inject } from "vue"

const router = inject("router");


const Goto = (name) => {
    router.push({ name: name });
}
</script>