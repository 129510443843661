<template>
  <div class="card-body bg-color h-100">

    <!-- <div class="title text-end">
      <i class="bi bi-megaphone" ><span style="font-size: 14px; margin-left: 5px; color: black">ร้องทุกข์</span></i>
    </div> -->

    <div class="body-text bg-custom  p-2 h-100"> 
      <div class="row mt-3 justify-content-center">
        <template v-for="title in Titles" :key="title">
            <div @click="setTitle(title.name)" class="appeal-item col-4 mb-3" >
              <div class="appeal-image">
                <img :src="title.icon" class="appeal-image-logo" alt="" />
                <div class="appeal-text fs-5 px-1">{{ title.name }}</div>
              </div>
              
            </div>
          </template>
      </div>
    </div>
    
  </div>
</template>

<script>
import { defineComponent, inject } from "vue";
import { SET_APPEAL } from "@/store/modules/AppealModel";
export default defineComponent({
  name: "Appeal-Title",
  setup() {
    const store = inject("store");
    const router = inject("router");
    const Titles = store.getters.getTitle;

    const setTitle = (val) => {
      let appeal = {
        title: val,
        id_line: store.getters.getAccessToken.accesstoken,
      };
      store.dispatch(SET_APPEAL, appeal);
      router.push({ name: "Appeal" });
    };

    return {
      Titles,
      setTitle,
    };
  },
});
</script>
<style scoped>
.bg-color { 
  background: var(--color);
}
.bg-custom{ 
   background: rgba(255, 255, 255, .6) !important;
   border-radius: 25px;
}
.card-body {
  padding: 0;
}
.title {
  background-color: white;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px 10px;
}
.title-text {
  font-size: 12px;
  margin-right: 5px;
}
.icon-logo {
  height: 27px;
  background-color: white;
  margin-left: 10px;
  border-radius: 50%;
  margin-top: -10px;
}
.body-text {
  height: 16rem;
  position: relative;
}
.hide-scrollbar {
  position: relative;
  height: 100%;
  overflow-y: hidden;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.scrollbar {
  height: 0.25rem;
  border-radius: 0.0625rem;
  position: absolute;
  bottom: 0.3125rem;
  left: calc(50% - 0.75rem);
  width: 1.5rem;
  background: #FFBE33;
}
.scrollbar-st {
  background: #ee4d2d;
  width: 10.801px;
  transform: translateX(0px);
}
.appeal-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.125rem;
}

.appeal-item {
  width: 26%;
  margin-inline: 10px;
  padding: 4px 0.0625rem;
  text-align: center;
  height: 6.3rem;
  background: linear-gradient(180deg, rgba(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  
  border: 1.3px solid linear-gradient(180deg, rgba(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);;
  border-radius: 50% 50% 10% 10% / 55% 55% 10% 10% ;
  overflow: hidden;
}
.appeal-image {
  position: relative;
}
.appeal-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 60px;
  height: 60px;
  /* margin-bottom: 10px; */
  mix-blend-mode: multiply;
}
.appeal-text { 
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
