<template>
    <div class="row">
      <div class="col-12">
        <div class="card concard card-shadow h-100"  style="min-height: 100dvh;">
          <Header></Header>
          <Appeal class="p-3 h-100"></Appeal>
          <Navbar></Navbar>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent, onMounted, inject } from "vue";
  import Header from "@/components/Header";
  import Appeal from "@/components/Appeal/Appeal";
  import Navbar from "@/components/Navbar";
  
  
  export default defineComponent({
    name: "Profile",
    components: {
      Header,
      Appeal,
      Navbar,
    },
    setup() {
      const store = inject("store");
      const router = inject("router");
  
      onMounted(() => {
        if (!store.getters.getAccessToken.accesstoken) {
          router.push({ name: "Home" });
        }
      });
    },
  });
  </script>
  
  <style scoped></style>
  