<template>
  <div class="card-header">
    <div class="row mt-3 mx-3">
      <div class="col">
        <div>
          <div class="bg-white p-2 rounded w-65">
            <span class="d-block fs-2 fw-bolder">{{ AppName }}</span>
            <span  class="d-block fs-3">{{ LineID }}</span>
          </div>
          <img src="@/assets/logo.png" class="m-2 position-absolute top-0 end-0 float-end" style="width: 120px;  border-radius: 50%; border-style: solid;   border-color: white;  border-width: 2px;" />
        </div>
      </div>
      <span class="d-flex text-white display-5 align-items-center mt-3 fw-bolder">
        <i class="bi bi-megaphone text-white pe-2"></i>
        รับเรื่องร้องทุกข์</span>
    </div>
  </div>
</template>

<script>
import liff from "@line/liff";
import { defineComponent } from "vue";
export default defineComponent({
  name: "Header",
  setup() {
    
    const AppName = process.env.VUE_APP_NAME;
    const LineID = process.env.VUE_APP_NAME_LINE;
    const setProfile = () => {
      liff.ready.then(() => {
        liff.openWindow({
          url: `line://app/${process.env.VUE_APP_LIFF_REGISTER}`,
          external: false,
        });
      });
    };

    return {
      setProfile,
      AppName,
      LineID

    };
  },
});
</script>

<style scoped>

.card-header {
  background-color: var(--color);
  position: relative;
  height: auto;
  /* min-height: 200px; */
  overflow: hidden;
  border-bottom: none !important;
  padding: 0 !important;
}

.bg-white {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.w-65{ 
  width: 65% !important;
}
</style>
