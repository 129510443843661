import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import Appeal from "@/views/appeal/Appeal.vue";
import Appeal_1 from "@/views/appeal/Appeal_1.vue";
import Appeal_Detail from "@/views/appeal/Appeal_Detail.vue";
import History from "@/views/dashboard/History.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/history",
    name: "History",
    component: History,
  },
  {
    path: "/appeal",
    name: "Appeal",
    component: Appeal,
  },
  {
    path: "/appeal/step1",
    name: "Appeal_1",
    component: Appeal_1,
  },
  {
    path: "/appeal_detail",
    name: "Appeal_Detail",
    component: Appeal_Detail,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
